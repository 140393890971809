import { Pipe, PipeTransform } from '@angular/core';
import { RS_NULL_DASH } from '../../constants/null-dash';

/** rsNullDash
 *
 * @Param{string} @Optional prefix - will display a prefix before value and if value is not empty
 *
 * @Param{string} @Optional suffix - will display a suffix after value and if value is not empty
 *
 * @Param{object{ nullValue: string | number | undefined, separatorsToStrip: string[] | undefined }} @Optional options
 * If separatorsToStrip is passed, it will strip all array members if they start or end the returned value
 * Ex: ['-', '/']: '- value' will return 'value' / '- value -' will return 'value' / 'value - value' will return 'value - value' / 'value - value /' will return 'value - value'
 * If nullValue is passed, it will consider that value as null and return the dash instead of the value
 *
 * Usage Examples
 *
 * ```html
 {{ value | rsNullDash: 'prefix' : 'suffix': { separatorsToStrip[], nullValye } }}

 {{ value | rsNullDash: '' : '': { nullValue: value } }} >> ―

 {{ value | rsNullDash: '' : '': { nullValue: otherValue } }} >> value of if empty ―

 {{ 'value' + '-' + 'value' + '/' + null | rsNullDash: '' : '': { separatorsToStrip: ['-', '/'] } }} >> 'value' - 'value'

 {{ undefined + '-' + 'value' + '/' + null | rsNullDash: '' : '': { separatorsToStrip: ['-', '/'] } }} >> 'value'

 {{ 0 | rsNullDash: '' : ' €'  }} >> 0 € or if empty ―

 {{ 10 | rsNullDash: '€ ' : ''  }} >> € 10 or if empty ―

 {{ 'value' | rsNullDash }} >> value or if empty ―
 ```
 *
 */

@Pipe({
  name: 'rsNullDash',
  standalone: true
})
export class RsNullDashPipe implements PipeTransform {

  public transform(
    value?: number | string | null,
    prefix: string = '',
    suffix: string = '',
    options?: {
      separatorsToStrip?: string[],
      nullValue?: number | string
    }
  ): string {

    switch (typeof value) {

      case 'number':
        if (value >= 0 && value !== null && value !== options?.nullValue || undefined) {
          return `${prefix}${value}${suffix}`;
        }
        break;

      case 'string':
        if (options?.separatorsToStrip?.length) {
          const regex = /undefined|null/g;
          value = (value as string).trim().replaceAll(regex, '');

          options.separatorsToStrip.forEach((replaceSeparators) => {
            const regex = new RegExp('^\\' + replaceSeparators + '|\\' + replaceSeparators + '$', 'g');
            value = (value as string).trim().replaceAll(regex, '');
          });
        }

        if (value.trim() && value.trim() !== options?.nullValue) {
          return `${prefix}${value}${suffix}`;
        }
        break;
    }

    return RS_NULL_DASH;
  }
}
