import { TranslationService, TranslationsLanguage } from '../../services/translate/translate.service';
import { Component, Input, OnInit, signal } from '@angular/core';
import { isMobiSkin } from '../../../scripts/applicationsSkinSetter';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { RentaDomains } from '../../models';
import * as moment from 'moment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { startWith } from 'rxjs';

/**
 * Uses [TranslationService]{@link TranslationService} to control current language
 * and display available languages in dropdown.
 */
@Component({
  selector: 'rs-footer',
  templateUrl: './rs-footer.component.html',
  styleUrls: ['./rs-footer.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule
  ],
  providers: [TranslationService]
})
export class RsFooterComponent implements OnInit {
	/** List of supported languages defined by their shorthand
	 *  ex. {'en', 'nl', ...}
	 *
	 * Defaults to all languages known by the
	 * TranslationService if not provided */
	@Input() public supportedLanguages: string[] = this.languages.map((language: TranslationsLanguage): string => language.short);
	/** Translation-key for the "Disclaimer" text displayed,
	 * component will automatically translate for given key
	 *
	 * Provide the translation-key in translation files
	 * or override with self-defined translation-key */
	@Input() public disclaimerKey = 'DISCLAIMER';
	/** Translation-key for the "Cookies" text displayed,
	 * component will automatically translate for given key
	 *
	 * Provide the translation-key in translation files
	 * or override with self-defined translation-key */
	@Input() public cookiesKey = 'COOKIES';
	/** Translation-key for the "Need help?" text displayed,
	 * component will automatically translate for given key
	 *
	 * Provide the translation-key in translation files
	 * or override with self-defined translation-key */
	@Input() public needHelpKey = 'NEED HELP';

	public copyrightYear!: number;
	public companyName?: string = isMobiSkin ? RentaDomains.EUROPE_COPY : RentaDomains.RENTA_COPY;
	protected readonly supportLinkDomain = isMobiSkin ? 'https://support.mobeyond.eu' : 'https://support.rentasolutions.org';
	protected readonly supportLink = signal<string>(this.supportLinkDomain);

	public constructor(
		private translate: TranslationService,
		private readonly ngxTranslateService: TranslateService,
	) {
	  this.ngxTranslateService.onLangChange
	    .pipe(
	      startWith({ lang: this.ngxTranslateService.currentLang }),
	      takeUntilDestroyed()
	    )
	    .subscribe(lang => {
	      this.supportLink.set(`${this.supportLinkDomain}/${lang.lang === 'de' ? 'en' : lang.lang}/support/home`);
	    });
	}

	/** @ignore */
	public get displayLanguages(): TranslationsLanguage[] {
	  return this.translate.languages.filter((language: TranslationsLanguage): boolean => {
	    return language.short !== this.translate.currentLanguage.short;
	  });
	}

	/** @ignore */
	public get languages(): TranslationsLanguage[] {
	  return this.translate.languages;
	}

	/** @ignore */
	public get currentLanguageLongString(): string {
	  return this.translate.currentLanguage.long;
	}

	/** @ignore */
	public get currentLanguageShortString(): string {
	  return this.translate.currentLanguage.short;
	}

	/** Generates the respective url for Renta disclaimer
	 *
	 * Uses [TranslationService]{@link TranslationService} to link to current language */
	public get currentDisclaimerURL(): string {
	  return `http://www.rentasolutions.org/${this.currentLanguageShortString}/disclaimer`;
	}

	/** Generates the respective url for Renta cookies
	 *
	 * Uses [TranslationService]{@link TranslationService} to link to current language */
	public get currentCookiesURL(): string {
	  return `http://www.rentasolutions.org/${this.currentLanguageShortString}/cookies`;
	}

	public ngOnInit(): void {
	  this.copyrightYear = moment().year();
	}

	/** @ignore */
	public setLanguage(language: { long: string; short: string }): void {
	  moment.locale(language.short);
	  this.translate.useLang(language.short);
	}
}
