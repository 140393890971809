import { AbstractControl, Validators } from '@angular/forms';

type RsLicensePlateValidatorError = {
  licensePlate: { value: string, valid: false, msg?: string }
} | null;

declare module '@angular/forms' {
  export namespace Validators {
    let rsLicensePlateValidator: (control: AbstractControl) => RsLicensePlateValidatorError;
  }
}

Validators.rsLicensePlateValidator = (control: AbstractControl<string>): RsLicensePlateValidatorError => {
  return control.rsHasValue() ? validateLicensePlate(control.value) : null;
}

function validateLicensePlate(licensePlate: string): RsLicensePlateValidatorError {
  if (licensePlate.trim() !== licensePlate || /\s/.test(licensePlate)) {
    return {
      licensePlate: {
        value: licensePlate,
        valid: false,
        msg: 'No spaces allowed'
      }
    };
  }
  if (/[^A-Z0-9]/.test(licensePlate)) {
    return {
      licensePlate: {
        value: licensePlate,
        valid: false,
        msg: 'Invalid characters'
      }
    };
  }

  return null;
}
